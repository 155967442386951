@import "./_reset.scss";
@import "./_carousel.scss";
@import "./_header.scss";
@import "./_popup.scss";

body {
  &.fixed {
    overflow: hidden;
  }
}
.wrapper {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.2;
  background-image: url(../img/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100vh;
  height: 100%;
}

.button {
  width: 100%;
  &__body {
    
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.content {
  &__wrapper {
    margin-top: 40px;
  }
  &__title {
    text-align: center;
    font-size: 42px;
    font-weight: 900;
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    color: #313131;
    &_small {
      font-size: 30px;
      margin-top: 55px;
      margin-bottom: 50px;
    }
  }
  &__number {
    color: #fff;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ad2925;
    line-height: 50px;
  }
}

.advantages {
  &__wrapper {
    margin: 0 auto;
    margin-top: 45px;
    max-width: 950px;
  }
  &__row {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
  }
  &__item {
    width: calc(25% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 35px;
  }
  &__name {
    text-align: center;
  }
  &__icon {
    width: 80px;
    height: 80px;
    border: 1px solid #303f9f;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    img {
      max-height: 43px;
    }
  }
}

.button-box {
  &__wrapper {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    &_popup {
      max-width: 250px;
      margin-top: 75px;
      .button-box__button {
        height: 50px;
      }
    }
  }
  &__button {
    position: relative;
    overflow: hidden;
    width: 100%;
    border: none;
    background-color: #303f9f;
    height: 70px;
    border-radius: 5px;
    cursor: pointer;
    &:before {
      content: "";
      margin-left: 60px;
      width: 15px;
      height: 300px;
      background: rgba(122, 130, 184, 0.5);
      -webkit-animation-delay: 0.05s;
      animation-delay: 0.05s;
      position: absolute;
      left: -40px;
      top: -150px;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      -webkit-transition: all 0.1s;
      transition: all 0.1s;
      -webkit-animation-name: slideme;
      animation-name: slideme;
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    @-webkit-keyframes slideme { 0% { left: -30px; margin-left: 0px; }
      30% { left: 110%; margin-left: 80px; }
      100% { left: 110%; margin-left: 80px; } }

    @keyframes slideme { 0% { left: -30px; margin-left: 0px; }
      30% { left: 110%; margin-left: 80px; }
      100% { left: 110%; margin-left: 80px; } }
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
  }
  &__caption {
    font-size: 16px;
    color: #fff;
  }
}

.error-box {
  color: red;
  padding-left: 5px;
  top: 40px;
  position: absolute;
  width: 100%;
  font-size: 12px;
}

@import "./_media.scss";