@media screen and (max-width: 1200px) {
  .carousel__navigations .owl-prev {
    left: 0;
  }
  .carousel__navigations .owl-next {
    right: 0;
  }
}
@media screen and (max-width: 1100px) {
  .carousel__wrapper {
    max-width: 800px;
  }
  .carousel__navigations .owl-next, .carousel__navigations .owl-prev {
    top: 65px;
  }
}
@media screen and (max-width: 992px) {
	.popup__wrapper {
    left: 0;
    margin-left: 0;
  }
  .popup__type {
    width: calc(33.333333% - 30px);
    margin-bottom: 20px;
  }
  .popup__size-radio {
    flex-wrap: wrap;
  }
  .popup__size-radio .radio {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 768px) {
  .content__title {
    font-size: 24px;
  }
  .header__wrapper {
    flex-direction: column;
  }
  .header__logo {
    margin-bottom: 15px;
  }
  .popup__wrapper {
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .progress__wrapper {
    margin-bottom: 30px;
  }
  .popup__wrapper {
    top: 0;
  }
  .popup__add-item {
    width: calc(50% - 30px);
    margin-bottom: 25px;
  }
  .popup__other-item {
    width: calc(100% - 30px);
    margin-bottom: 20px;
  }
  .popup__other {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 600px) {
  .popup__type {
    width: calc(50% - 30px);
    margin-bottom: 20px;
  }
  .popup__title {
    font-size: 22px;
  }
  .popup__size-img {
    margin-right: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
  }
  .popup__end-box {
    flex-wrap: wrap;
  }
  .popup__end-input-box {
    justify-content: center;
  }
  .popup__end-label {
    text-align: center;
  }
  .popup__end-item {
    width: calc(100% - 30px);
    margin-bottom: 20px;
  }
  .popup__size-par {
    margin-left: 0;
    width: 100%;
  }
  .popup__size-box {
    flex-wrap: wrap;
  }
  .popup__op-item {
    width: calc(50% - 30px);
  }
  .popup__add-item {
    width: calc(100% - 30px);
  }
}
@media screen and (max-width: 500px) {
	.header__contacts {
    flex-direction: column;
  }
  .header__address {
    text-align: center;
  }
  .header__phones {
    text-align: center;
  }
  .advantages__item {
    width: calc(50% - 30px);
  }
  .advantages__row {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 450px) {
	.button-box__button {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .popup__type  {
    width: calc(100% - 30px);
  }
  .popup__op-item {
    width: calc(100% - 30px);
  }
}

