.popup {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: none;
  &_open {
    display: block;
    overflow: scroll;
  }
  &__thanks {
    display: none;
    &_open {
      display: block;
    }
  }
  &__size-par {
    margin-left: 15px;
  }
  &__size-img {
    margin-right: 15px;
  }
  &__size-radio {
    display: flex;
    width: 100%;
    margin-bottom: 35px;
    .radio {
      width: auto;
    }
    .radio__btn+label {
      padding-left: 40px;
      margin-right: 45px;
    }
    .radio__btn:not(checked)+label:before {
      top: -2px;
    }
    .radio__btn:not(checked)+label:after {
      top: 2px;
    }
  }
  &__input-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
  }
  &__op-item {
    width: calc(33.333333% - 30px);
    margin-left: 15px;
    margin-right: 15px;
  }
  &__options {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  &__unit {
    margin-left: 5px;
  }
  &__input {
    padding-left: 5px;
    height: 38px;
    width: 100px;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
  }
  &__label {
    min-width: 70px;
  }
  &__wrapper {
    position: absolute;
    top: 4%;
    left: 50%;
    margin-left: -496px;
    width: 100%;
    max-width: 992px;
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 75px;
    padding-right: 75px;
    background-color: #fff;
    z-index: 100;
  }
  &__other-name {
    margin-bottom: 30px;
    font-weight: 700;
  }
  &__other {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
  }
  &__other-item {
    width: calc(33.333333% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    .popup__label {
      min-width: 30px;
    }
    .popup__input {
      width: 75px;
    }

  }
  &__end-caption {
    font-size: 18px;
    text-align: center;
    margin-bottom: 25px;
  }
  &__end-img {
    text-align: center;
  }
  &__end-box {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    align-items: center;
    .popup__input {
      width: 235px;
    }
  }
  &__end-input-box {
    display: flex;
  }
  &__end-icons {
    margin-left: 7px;
  }
  &__end-text {
    font-size: 14px;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
  }
  &__end-item {
    width: calc(50% - 30px);
    margin-bottom: 25px;
    margin-left: 15px;
    margin-right: 15px;
  }
  &__other-inputs {
    .radio {
      width: auto;
      margin-bottom: 20px;
    }
    .radio__btn+label {
      padding-left: 40px;
      margin-right: 45px;
    }
    .radio__btn:not(checked)+label:before {
      top: -2px;
    }
    .radio__btn:not(checked)+label:after {
      top: 2px;
    }
  }
  &__op-item-img {
    img {
      max-width: 100%;
    }
    margin-bottom: 15px;
    text-align: center;
  }
  &__op-item-name {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  &__title {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    max-width: 420px;
    margin: 0 auto;
    margin-bottom: 45px;
    .content__number {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
  } 
  &__type-box {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  &__type {
    width: calc(20% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      max-width: 100%;
    }
  }
  &__type-name {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  &__steps {
    color: #363636;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  &__item {
    display: none;
    &_active {
      display: block;
    }
  }
  &__size-box {
    display: flex;
  }
  &__add-box {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  &__add-item {
    width: calc(25% - 30px);
    margin-left: 15px;
    margin-right: 15px;
  }
  &__add-img {
    text-align: center;
    margin-bottom: 15px;
  }
  &__add-name {
    text-align: center;
    margin-bottom: 25px;
    min-height: 38px;
  }
}

.radio {
  width: 25px;
  height: 25px;
  position: relative;
  margin: 0 auto;
}

.radio__btn {
  position: absolute;
  vertical-align: top;
  width: 17px;
  height: 17px;
}
.radio__btn + label {
  cursor: pointer;
}
.radio__btn:not(checked) {
  z-index: -1;
  opacity: 0;
}
.radio__btn:not(checked) + label {
  position: relative;
}
.radio__btn:not(checked) + label:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  width: 22px;
  height: 22px;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  background: #FFF;
}
.radio__btn:not(checked) + label:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #303f9f;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.5);
  opacity: 0;
  transition: .2s;
}
.radio__btn:checked + label:after {
  opacity: 1;
}

.checkbox {
  width: 25px;
  height: 25px;
  position: relative;
  margin: 0 auto;
}
.checkbox__btn {
  position: absolute;
  vertical-align: top;
  width: 17px;
  height: 17px;
}
.checkbox__btn + label {
  cursor: pointer;
}
.checkbox__btn:not(checked) {
  z-index: -1;
  opacity: 0;
}
.checkbox__btn:not(checked) + label {
  position: relative;
}
.checkbox__btn:not(checked) + label:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  width: 22px;
  height: 22px;
  border: 1px solid #e9e9e9;
  
  background: #FFF;
}
.checkbox__btn:not(checked) + label:after {
  content: "";
  position: absolute;
  border-bottom: 4px solid #303f9f;
  border-left: 4px solid #303f9f;
  top: -7px;
  left: 0px;
  transform-origin: 50% 50%;
  transform: rotate(-45deg);
  width: 24px;
  height: 14px;
  opacity: 0;
  transition: .2s;
}
.checkbox__btn:checked + label:after {
  opacity: 1;
}

.progress {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-bottom: 60px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 5px;
      top: 50%;
      margin-top: -2.5px;
      background-color: #e1e1e1;
      width: calc(100% - 5px);
      z-index: 1;
    }
  }
  &__bar {
    min-width: 4%;
    max-width: 99.9%;
    height: 5px;
    position: absolute;
    top: 50%;
    margin-top: -2.5px;
    background-color: #303f9f;
    z-index: 1;
    border-radius: 2px;
  }
  &__point {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #e1e1e1;
    text-align: center;
    line-height: 24px;
    color: #bdbdbd;
    position: relative;
    z-index: 2;
    cursor: pointer;
    &_active {
      background-color: #303f9f;
      color: #fff;
    }
  }
}