@import "../../node_modules/owl.carousel/src/scss/owl.carousel.scss";
.carousel {
  &__wrapper {
    width: 100%;
    max-width: 920px;
    margin: 0 auto;
    margin-bottom: 45px;
    overflow: hidden;
  }
  &__description {
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 20px;
  }
  &__item {
    color: #000;
  }
  &__navigations {
    position: relative;
    width: 100%;
    .owl-prev,
    .owl-next {
      top: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border: none;
      background-color: #303f9f;
      width: 50px;
      height: 50px;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &:after {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        border-top: 3px solid #fff;
        border-left: 3px solid #fff;
      }
    }
    .owl-prev {
      left: 80px;
      &:after {
        transform: rotate(-45deg);
        margin-left: 5px;
      }
    }
    .owl-next {
      right: 80px;
      &:after {
        transform: rotate(135deg);
        margin-right: 6px;
      }
    }
  }
}

.owl-carousel.owl-drag .owl-item {
  transform: scale(0.75);
  transition: all 0.5s ease 0s;
}
.owl-carousel.owl-drag .owl-item.par {
  transform: scale(0.8);
}
.owl-carousel.owl-drag .owl-item.center {
  transform: scale(1);
}
